<template>
  <div class="container">
    <div class="flex-box app">
      <div class="logo-box">
        <div class="icon icon-dice"></div>
        <div class="logo"><a href="/">How Are The Odds</a></div>
      </div>
      <div @click="openMenu" class="burger-menu-box icon icon-menu">
      </div>
      <BurgerMenu :isOpen="isMenuOpen" @update:isOpen="isMenuOpen = $event" />
    </div>
    <router-view />
  </div>
  <FixedNavigation v-if="!isRootRoute" />
  <div class="footer-home" v-if="isRootRoute">
    <div class="footer-home__inner">
      <span>© 2023 How Are The Odds</span>
      <div class="footer-home__links">
        <router-link to="/impressum">
          <li>Impressum</li>
        </router-link>
        <router-link to="/datenschutz">
          <li>Datenschutzerklärung</li>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import FixedNavigation from "@components/FixedNavigation.vue";
import BurgerMenu from "@components/molecules/BurgerMenu.vue";
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    BurgerMenu,
    FixedNavigation,
  },
  setup() {
    const isMenuOpen = ref(false);
    const route = useRoute();
    const isRootRoute = ref(route.path === '/');
    function openMenu() {
      isMenuOpen.value = !isMenuOpen.value;
    }

    watch(route, () => {
      isRootRoute.value = route.path === '/';
    });

    return {
      openMenu,
      isMenuOpen,
      isRootRoute,
    };
  },
};
</script>

<style lang="scss">
@import './assets/styles/iconfont';

#app {
  font-family: $fontFamilyPrimary;
  color: $primaryText;
}

.logo {
  font-size: 20px;
  font-family: "Comic Sans MS", sans-serif;

  a {
    color: $secondary;
    font-weight: bold;
  }
}

.container {
  margin-bottom: 5rem !important;
}

.icon-dice {
  font-size: 36px;
  color: $secondary;
}

.logo-box {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.flex-box.app {
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: row !important;
}

.burger-menu-box {
  font-size: 30px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .footer-home__inner {
    flex-direction: column-reverse;
    align-items: start !important;
  }
}

.footer-home {
  position: relative;
  bottom: 0;
  width: 100%;
  list-style: none;
  background: $primary;

  &__inner {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      display: contents;

      &:hover {
        color: $secondary;
      }
    }
  }
}
</style>