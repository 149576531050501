import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () =>
        import("../views/HomeView.vue"),
    meta: {
      title: "How Are The Odds | Das Trinkspiel",
      description: "Wähle Kategorien und starte das Trinkspiel mit deinen Freunden.",
    },
  },
  {
    path: "/start-game",
    name: "startGame",
    component: () =>
        import("../views/StartGameView.vue"),
    meta: {
      title: "How Are The Odds | Neues Spiel starten",
      description: "Wähle Kategorien und starte das Trinkspiel mit deinen Freunden.",
    },
  },
  {
    path: "/player",
    name: "player",
    component: () =>
      import("../views/PlayerView.vue"),
    meta: {
      title: "How Are The Odds | Spielerliste",
      description: "Wer spielt mit?",
      noindex: true,
      nofollow: true
    },
  },
  {
    path: "/winner",
    name: "winner",
    component: () =>
        import("../views/WinnerView.vue"),
    meta: {
      title: "How Are The Odds | Bestenliste",
      description: "Zeige den Gewinner der letzten Spiele.",
      noindex: true,
      nofollow: true
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
        import("../views/SettingsView.vue"),
    meta: {
      title: "How Are The Odds | Einstellungen",
      description: "Spiel Einstellungen.",
      noindex: true,
      nofollow: true
    },
  },
  {
    path: "/rules",
    name: "rules",
    component: () =>
        import("../views/RulesView.vue"),
    meta: {
      title: "How Are The Odds | Spielregeln",
      description: "Spielregeln von How Are The Odds.",
    },
  },
  {
    path: "/download",
    name: "download",
    component: () =>
        import("../views/DownloadView.vue"),
    meta: {
      title: "How Are The Odds | Downloads",
      description: "Lade die App auf dein Smartphone herunter.",

    },
  },
  {
    path: "/game",
    name: "game",
    component: () =>
        import("../views/GameView.vue"),
    meta: {
      title: "How Are The Odds | Laufendes Spiel",
      description: "Lade deine Freunde ein und spiele mit!",
      noindex: true,
      nofollow: true
    },
  },
  {
    path: "/impressum",
    name: "impress",
    component: () =>
        import("../views/ImpressView.vue"),
    meta: {
      title: "How Are The Odds | Impressum",
      description: "Rechtliche Informationen zur Webseite.",
    },
  },
  {
    path: "/datenschutz",
    name: "dataProtection",
    component: () =>
        import("../views/DataProtectionView.vue"),
    meta: {
      title: "How Are The Odds | Datenschutzerklärung",
      description: "Datenschutzerklärung der Webseite von How Are The Odds.",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
