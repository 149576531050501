<template>
  <nav class="fixed-navigation">
    <router-link to="/start-game">
      <div class="icon icon-play3"></div>
    </router-link>
    <router-link to="/player">
      <div class="icon icon-users"></div>
    </router-link>
    <router-link to="/winner">
      <div class="icon icon-trophy"></div>
    </router-link>
    <router-link to="/settings">
      <div class="icon icon-cogs"></div>
    </router-link>
    <router-link to="/rules">
      <div class="icon icon-hammer2"></div>
    </router-link>
    <router-link to="/download">
      <div class="icon icon-download"></div>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "fixedNavigation"
}
</script>

<style lang="scss">
.fixed-navigation {
  position: fixed;
  display: flex;
  justify-content: center;
  gap: 2rem;
  bottom: 0;
  right: 0;
  background: $primary;
  color: $white;
  width: 100%;
  padding: 1rem 0;
  z-index: 150;
  height: 32px;

  a {
    gap: 1rem;
    display: block;
    border: 1px solid $primary;

    &:hover {
      .icon, span {
        color: $secondary;
      }
    }
  }

  .router-link-active {
    .icon, span {
      color: $secondary;
    }
  }

  .icon {
    font-size: 2rem;
    margin-bottom: 2rem;

    &:hover {
      color: $secondary;
    }
  }
}
</style>