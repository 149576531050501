<template>
  <div :class="menuIsOpen ? 'burger-menu is-open' : 'burger-menu'" @click="closeMenu">
    <div className="menu">
      <ul>
        <router-link @click="closeMenu" to="/">
          <li>Home</li>
        </router-link>
        <router-link @click="closeMenu" to="/game">
          <li>Neues Spiel starten</li>
        </router-link>
        <router-link @click="closeMenu" to="/start-game">
          <li>Kategorien auswählen</li>
        </router-link>
        <router-link @click="closeMenu" to="/player">
          <li>Spieler hinzufügen</li>
        </router-link>
        <router-link @click="closeMenu" to="/winner">
          <li>Gewinnerliste</li>
        </router-link>
        <router-link @click="closeMenu" to="/rules">
          <li>Spielregeln</li>
        </router-link>
        <router-link @click="closeMenu" to="/settings">
          <li>Einstellungen</li>
        </router-link>
      </ul>
      <ul class="information-list">
<!--        <li @click="closeMenu">App herunterladen</li>-->
        <router-link @click="closeMenu" to="/">
          <li>Informationen</li>
        </router-link>
        <router-link @click="closeMenu" to="/impressum">
          <li>Impressum</li>
        </router-link>
        <router-link @click="closeMenu" to="/datenschutz">
          <li>Datenschutzerklärung</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menuIsOpen = computed(() => props.isOpen);

    function closeMenu() {
      menuIsOpen.value = false;
      emit('update:isOpen', false);
    }

    return {
      menuIsOpen,
      closeMenu,
    };
  },
};
</script>

<style lang="scss">

.burger-menu {
  position: fixed;
  z-index: 999;
  background: white;
  right: -400px;
  top: 65px;
  width: 70%;
  height: 100%;
  transition: .6s ease-in-out;
  visibility: hidden;

  &.is-open {
    visibility: visible;
    right: 0;

    &:before {
      background: rgba(0,0,0,0.8);
      left: 0;
    }
    a {
      &:hover {
        color: $secondary;
      }
    }
  }

  &:before {
    content: '';
    z-index: 99;
    position: fixed;
    left: -100px;
    height: 100%;
    width: 30%;
    transition: .6s ease-in-out;
  }

  .menu {
    padding: 1rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 1rem;
      }
    }
    .information-list {
      border-top: 1px solid $background;
      margin-top: 1.5rem;
      padding-top: 1.5rem;

      li {
        font-size: 14px;
      }
    }
  }
}

</style>
